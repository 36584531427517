import React, { useState, useEffect } from 'react'
import { PopupModal } from 'react-calendly'
import { USA2023 } from '../../util/conditionalLinks'

export default function GitexPopupModal({ open, closeGitexModal, url }) {

    const [current, setCurrent] = useState('')

    useEffect(() => {
        setCurrent(window.location.pathname)
    }, [])
    useEffect(() => {
        open && USA2023.includes(current) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'     
    },[open])
    const closeGitexModal1 = () => {
        closeGitexModal()
        sessionStorage.setItem("stoppopup",true)
    }
    return (
        <>
            {USA2023.includes(current) && <PopupModal
                // url="https://meetings.hubspot.com/bde11"
                // url='https://calendly.com/webmob/ces-2023'
                url={url}
                // pageSettings={this.props.pageSettings}
                // utm={this.props.utm}
                // prefill={this.props.prefill}
                onModalClose={closeGitexModal1}
                open={open}
                rootElement={document.getElementById("root")}
            />}
        </>
    )
}
